.AuthMainDiv{
    display: flex;
    margin-top: 6%;
    width: 100%;
    height: 100vh;
    margin-left: 40px; /* Adjust the left margin as needed */
  margin-right: 40px; /* Adjust the right margin as needed */
  width: calc(100% - 80px); /* Calculate the width of the div minus the total margins */
  box-sizing: border-box; 
}
.divsub1 {
    width: 47%;
    padding-top: 10vh;
    height: 100%;
    display: flex;
    justify-content: right;
    
    
}

    .divsub2{
        
        width: 5%;
        height: 100%;
        font-weight: bold;
        color: grey;
       
        padding-left: 2%;
        }
        .divsub3{
            
            width: 45%;
        display: flex;
        justify-content: left;
        height: 100%;
        
        padding-top: 2vh;
        }

        .separator {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 40vh;  /* Takes full viewport height */
        }

        .separator-line {
            flex-grow: 1;
            width: 1px;
            background-color: #ccc;
            margin: 10px 0;
        }

        .separator-circle {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 50%;
            padding: 5px 10px;
        }

        .social-buttons {
            width: 45%;
            padding-top: 0px;
        }
        
        .btn {
            display: flex;
            align-items: center;
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 10px;
            font-size: 16px;
            transition: 0.3s;
        }
        
        .btn:last-child {
            margin-bottom: 0;
        }
        
        .btn img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
        
        .facebook {
            background-color: #1877f2;
            color: #fff;
        }
        
        .twitter {
            background-color: #1da1f2;
            color: #fff;
            margin-top: 14%;
        }
        
        .google {
            background-color: #ea4335;
            color: #fff;
            margin-top: 14%;
        }
        
        .btn:hover {
            opacity: 0.9;
        }
        
        /* Media Queries for Small Screens */
        @media (max-width: 768px) {
            .divsub1 {
                width: 100%;
                justify-content: center;
            }
        
            .social-buttons {
                width: 100%;
            }
        
            .btn {
                font-size: 14px;
            }
        
            .btn img {
                width: 20px;
                height: 20px;
            }
        }



        .login-container {
            width: 300px;
            padding-left: 10%;
            
           
            font-family: Arial, sans-serif;
        }
        
        h2 {
            text-align: center;
            margin-bottom: 20px;
        }
        
        .input-group {
            margin-bottom: 20px;
        }
        
        .input-group label {
            display: block;
            margin-bottom: 5px;
        }
        
        .input-group input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
        }
        
        .options-group {
            margin-bottom: 20px;
        }
        
        .options-group input[type="checkbox"] {
            margin-right: 10px;
        }
        
        .login-button {
            background-color: #ff5050;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            width: 100%;
            cursor: pointer;
        }
        
        .links-group {
            text-align: center;
            margin-top: 10px;
        }
        
        .links-group a {
            color: #0077cc;
            text-decoration: none;
        }
        
        .links-group span {
            margin: 0 10px;
        }
        