
.div{
  width: 100%;
  height: 2px;
  background-color:orange;
  margin-left: 20px; /* Adjust the left margin as needed */
  margin-right: 20px; /* Adjust the right margin as needed */
  width: calc(100% - 40px); /* Calculate the width of the div minus the total margins */
  box-sizing: border-box; 
}
