/* Footer Styles */
footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    
  }
  
  .footer-content {
   padding-right: 3%;
    margin: 0 auto;
    display: flex;
    justify-content: right;
    align-items: center;
   
    cursor: pointer;
  }
  
  .footer-links {
    display: flex;
    gap: 20px;
  }
  
  .footer-links a {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
  }
  
  /* Media Queries for Responsiveness */
  @media screen and (max-width: 768px) {
   
  
    .footer-links a {
      margin-bottom: 10px;
    }
  }
 