.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    
  }
  
  .signup-box {
    width: 400px;
    padding: 60px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .signup-box h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .signup-box div {
    margin-bottom: 15px;
  }
  
  .signup-box label {
    display: block;
    margin-bottom: 5px;
  }
  
  .signup-box input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .signup-box button {
    width: 100%;
    padding: 10px;
    background-color: #e74c3c;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .signup-box p {
    color: red;
    text-align: center;
  }
  