.navbarMainDiv{
margin-bottom: 6vh;
}
.map-container{
    width: 100%;
    height: 75vh;
    position: relative;
    margin-top: 5px;
}

.search-box {
    position: absolute;
    top: 10px;
    left: 10px;

    z-index: 10;
  }
  
  .search-box input {
    width: 200px;
    height: 25px;
    padding: 10px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }
  
  button {
    padding: 10px 15px;
    border: none;
    background-color: red;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Style for the report form */
div[style*="position: absolute; right: 10px; bottom: 60px;"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

div[style*="position: absolute; right: 10px; bottom: 60px;"] input,
div[style*="position: absolute; right: 10px; bottom: 60px;"] textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

div[style*="position: absolute; right: 10px; bottom: 60px;"] textarea {
    height: 50px; /* adjust based on your preference */
    resize: vertical;
}

div[style*="position: absolute; right: 10px; bottom: 60px;"] button[type="submit"] {
    width: 100%;
}
