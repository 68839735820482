.MainDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 18vh;
}

.imgname {
    width: 100px;
    height: 100px;
}

.div1 {
    display: flex;
    align-items: center;
    height: 18vh;
    padding-left: 35px;
}

.p1 {
    font-size: 50px;
    margin-left: 15px;
    font-weight: 400;
}

.div2 {
    display: flex;
    height: 18vh;
    padding-right: 70px;
}

.head1 {
    margin-right: 60px;
    cursor: pointer;
    margin-top: 13vh;
}

.head2 {
    cursor: pointer;
    margin-top: 13vh;
}

/* Media Queries for Small Screens */
@media (max-width: 768px) {
    .MainDiv {
        flex-direction: column;
        height: auto;
    }

    .div1, .div2 {
        width: 100%;
        justify-content: center;
        padding: 10px;
    }

    .p1 {
        font-size: 30px;
    }

    .head1, .head2 {
        margin-top: 20px;
        margin-right: 20px;
    }
}
